body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: "Poppins", sans-serif;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

//font
/* devanagari */
@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    src: local('Poppins Regular'), local('Poppins-Regular'), url(./assets/fonts/pxiEyp8kv8JHgFVrJJbecmNE.woff2) format('woff2');
    unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}

/* latin-ext */
@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    src: local('Poppins Regular'), local('Poppins-Regular'), url(./assets/fonts/pxiEyp8kv8JHgFVrJJnecmNE.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    src: local('Poppins Regular'), local('Poppins-Regular'), url(./assets/fonts/pxiEyp8kv8JHgFVrJJfecg.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

//global
ul li {
    list-style: none;
}

a {
    color: #4e9de6;
}

label {
    color: #212121;
}

.hide {
    display: none !important;
}

.init-loading {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}


.block {
    display: block !important;
}

.flex-center {
    display: flex;
    justify-content: space-around;
    text-align: center;
}

.flex-avg {
    display: flex;

    &>* {
        flex: 1;
    }
}

.align-center {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.visible-hide {
    visibility: hidden;
}

.pointer {
    cursor: pointer;
}

.full-width {
    width: 100%;
}

.flex-between {
    display: flex;
    justify-content: space-between;
}

//text
.text-center {
    text-align: center;
}

.txt-grey {
    color: #878787 !important;
}

.txt-primary {
    color: rgba(68, 114, 196, 1) !important;
}

.txt-dark {
    color: #212121 !important;
}

.txt-danger,
.text-danger {
    color: #e3ad00 !important;
}

.txt-orange {
    color: #e3ad00
}

.font-12 {
    font-size: 12px !important;
}

.font-18 {
    font-size: 18px !important;
}

.font-20 {
    font-size: 20px !important;
}

.font-22 {
    font-size: 22px !important;
}

.font-30 {
    font-size: 30px !important;
}
.font-42 {
    font-size: 42px;
}

.font-45 {
    font-size: 45px;
}

.font-70 {
    font-size: 70px;
}

.weight-500 {
    font-weight: 500 !important;
}

.weight-600 {
    font-weight: 600 !important;
}

.bold {
    font-weight: bold !important;
}

.lowercase {
    text-transform: lowercase;
}



.text-center {
    text-align: center;
}

.text-right {
    text-align: right !important;
}

.text-left {
    text-align: left !important;
}

//margin
.mt-20 {
    margin-top: 20px !important;
}
.mt-10 {
    margin-top: 10px !important;
}
.mt-5 {
    margin-top: 5px !important;
}
.mb-10 {
    margin-bottom: 10px !important;
}

/*padding*/
.clear-padding {
    padding: 0 !important;
}

.pa-30 {
    padding: 30px;
}

.pb-10 {
    padding-bottom: 10px;
}

.pl-16 {
    padding-left: 16px;
}

.pd-lf-15 {
    padding-left: 15px;
    padding-right: 15px;
}

/*table*/
table {
    .table-operation {
        span {
            cursor: pointer;
        }
    }

    &.border-table {
        border-collapse: collapse;
        width: 100%;

        tr {
            td {
                border: 1px solid;
                padding-left: 10px;
            }
        }

        .table-group {
            td {
                padding-left: 2px;
                font-weight: 600;
            }
        }
    }
}



//antd
.ant-form-item-label>label {
    color: #333;
}

.ant-input {
    color: #333;
}

/*table*/
.ant-table {
    font-size: 13px;
    overflow: auto;
}

.ant-table-tbody>tr>td {
    border-color: #dedede;
    border-left: none;
    border-bottom: none;
    padding: 16px;
    border-top: 1px solid #dedede;
    vertical-align: middle;
    line-height: 1.42857143;
    cursor: pointer;
}

.ant-table-tbody>tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)>td {
    background-color: rgba(33, 33, 33, 0.05) !important;
}

.ant-table-thead>tr>th {
    background: #fff;
    border-bottom: 0 !important;
    /*text-transform: uppercase;*/
    font-size: 12px;
    color: #212121;
}

.ant-table-thead>tr>th.ant-table-column-has-actions.ant-table-column-has-sorters:hover,
.ant-table-thead>tr>th .ant-table-header-column .ant-table-column-sorters:hover::before {
    background: #fff;
}

/*input*/
.ant-input {
    border-radius: 0;
    border: 1px solid rgba(33, 33, 33, 0.12);

}

.ant-input:hover,
textarea:hover {
    border-color: rgba(33, 33, 33, 0.3);
    box-shadow: none;
}
textarea:focus {
    border-color: #40a9ff;
    outline-width: 0px;
}
// .ant-input:focus {
//     // border-color: rgba(33, 33, 33, 0.3);
//     box-shadow: none;
//     outline: -webkit-focus-ring-color auto 1px;
//     outline-color: -webkit-focus-ring-color;
//     outline-style: auto;
//     outline-width: 1px;
//     transition: all 0s !important;
// }

// .ant-select-focused {
//     .ant-select-selection {
//         border: 1px solid rgba(33, 33, 33, 0.12) !important;
//         outline: -webkit-focus-ring-color auto 1px !important;
//         outline-color: -webkit-focus-ring-color !important;
//         outline-style: auto !important;
//         outline-width: 1px !important;
//         transition: all 0s !important;
//         box-shadow: none !important;
//     }
// }


.has-error .ant-input:focus {
    border-color: #ed6f56;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 6px #ce8483;
}

.ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled) {
    border-color: rgba(33, 33, 33, 0.3);
}

//button

.ant-btn {
    padding: 10px 25px;
    text-transform: capitalize;
    border-radius: 2px;
    transition: all 0.3s ease;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.428571;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    height: auto;
    background: gainsboro;
    border: solid 1px gainsboro;
    color: #212121;
}

.ant-btn:hover {
    background: rgba(220, 220, 220, 0.85);
    border: solid 1px rgba(220, 220, 220, 0.1);
    color: #212121;
}

.ant-btn-primary {
    background: #4e9de6;
    border: solid 1px #4e9de6;
    padding: 10px 25px;
    color: #fff;
}

.ant-btn-primary:hover {
    background: rgba(78, 157, 230, 0.85);
    border: solid 1px rgba(78, 157, 230, 0.1);
    color: #fff;
}

.ant-btn-danger {
    background: #ed6f56;
    border: solid 1px #ed6f56;
    color: #fff;
}

.ant-btn-danger:hover {
    background: rgba(237, 111, 86, 0.85);
    border: solid 1px rgba(237, 111, 86, 0.1);
    color: #fff;
}

.btn-black {
    background: #000 !important;
    color: white;
    padding: 5px 30px;
    font-weight: bold;
}

.btn-default {
    padding: 5px 30px;
    font-weight: bold;
}

button+button {
    margin-left: 5px;
}

.confirm-container {
    background: none !important;
    border: 0 !important;
    box-shadow: none !important;
    padding: 0 !important;
    margin: 0 !important;
}


.ant-popover-buttons button {
    line-height: 0.7;
}

//select
.ant-select-selection {
    border-radius: 0;
}

.ant-select-selection:hover {
    border-color: rgba(33, 33, 33, 0.3);
    box-shadow: none;
    outline: 0 none;
}

.ant-select-dropdown {
    z-index: 1301;
}

select.form-control {
    padding: 6px 12px;
    box-sizing: border-box;
    color: #333;
}

/*chart*/
.chart-small {
    max-width: 500px;
    margin: 0 auto;
}

.chart-small-full-w {
    max-width: 1000px;
    margin: 0 auto;
}

/*color*/
.color-white {
    color: white !important;
}

.color-red {
    color: #ca0c1c !important;
}
.color-org{
    color:orange !important;
}

/*map*/
.jvectormap-zoomin,
.jvectormap-zoomout,
.jvectormap-goback {
    box-sizing: content-box !important;
}

/*datetime*/
.rdt {
    display: inline-block;

    &>input {
        border: 1px solid #d9d9d9;
        border-width: 1px;
        padding: 4px 10px;
        box-sizing: border-box;
        outline: none;

        &:focus {
            border-color: rgba(33, 33, 33, 0.3);
        }
    }
}

/*tabs*/
.ant-tabs-nav .ant-tabs-tab-active {
    color: #212121;
}

/*modal*/
.ant-modal {
    max-width: 99%;

    textarea {
        width: 100%;
        padding: 10px;
        color: initial;
    }


    &.ant-modal-confirm-success {
        top: 20%;

        .ant-modal-confirm-body {
            text-align: center;

            &>i {
                display: none;
            }
        }

        .ant-modal-confirm-content {
            margin-left: 0 !important;
        }

        .ant-modal-confirm-btns {
            float: none;
            text-align: center;

        }
    }
}

.ant-modal.disbaled {
    .ant-modal-footer {
        .ant-btn-primary {
            color: rgba(0, 0, 0, 0.25);
            background-color: #f5f5f5;
            border-color: #d9d9d9;
            text-shadow: none;
            box-shadow: none;
            cursor: no-drop;
        }
    }
}



/*bg*/




.form-group {
    margin-bottom: 15px;
    margin-right: -15px;
    margin-left: -15px;


    .control-label {
        padding-top: 7px;
        margin-bottom: 0;
        text-align: right;
        text-transform: capitalize;
    }

    .form-control-static {
        min-height: 34px;
        padding-top: 7px;
        padding-bottom: 7px;
        margin-bottom: 0;
    }
}


hr {
    display: block;
    margin-top: 10px;
    margin-bottom: 35px;
    width: 100%;
    border-top: 1px solid #4e9de6;
}

.recharts-tooltip {
    background: white;
    box-shadow: 0 0 5px #ccc;
    padding: 10px;
}

//editor
.tox.tox-silver-sink.tox-tinymce-aux {
    .tox-notifications-container {
        display: none;
    }
}

//collapse

.ant-collapse {
    background-color: transparent;
    border: 0;
}

.collapse-hide {
    .ant-collapse-content-inactive {
        display: block;
        height: 0;
        visibility: hidden;
        border: 0;
    }
}

span.serial {
    display: inline-block;
    min-width: 20px;
}

.jvectormap-tip {
    top: -100px;
}
.icon-sp {
    background: url(/images/icon_sp.png);
    height: 18px;
    min-width: 18px !important;
    background-size: 100%;
}
.relative{
    position: relative;
}
.famfamfam-flags {
    display: inline-block;
}
.pointer{
    cursor:pointer
}