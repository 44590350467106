@import '~antd/dist/antd.css';
@import './assets/css/font-awesome.css';
@import './assets/css/simple-line-icons.css';
@import './assets/css/bootstrap.css';

.clear-margin {
    margin: 0;
}
.historian-tooltip {
    z-index: 2;
    pointer-events: all !important;
}
.ant-spin-nested-loading {
    &.transparent {
        .ant-spin-text {
            text-shadow: none !important;
        }
        .ant-spin-container {
            &::after {
                background: transparent !important;
            }
        }
    }
}

.inline-block{
    display: inline-block;
}

.vertical-bottom{
    vertical-align: bottom;
}