.full-layout {
    header {
        padding-top: 19px;
        padding-left: 15px;
    }

    .logo {
        width: 120px;
        margin-right: 10px;
    }

    .brand-text {
        display: inline-block;
        font-size: 21px;
        text-transform: capitalize;
        vertical-align: middle;
        color: #333;
    }

    main > h1 {
        text-align: center;
    }
}
