.authentication-license2 {
    width: 100%;
    background-size: cover;
    overflow: auto;
    background-position: right top;
    padding: 0 50px;


    h1 {
        margin: 50px 0;
        text-align: left !important;
        color: black;
        font-size: 50px;
    }

    p {
        font-size: 15px;
        color: black;
    }

    label {
        color: black !important;
    }

    .license-key-form {
        display: flex;
        align-items: center;

        & > div {
            margin: 0;
        }

        button {
            margin-left: 10px;
            margin-top: 40px;
        }

        .ant-form-item {
            width: 40%;

            .ant-form-item-label {
                width: 120px;
                text-align: left;
            }

            .ant-form-item-control-wrapper {
                width: 100%;
            }
        }
    }
}
