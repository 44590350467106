.login {
    .login-form {
        max-width: 400px;
        width: 100%;
        margin: 0 auto;
        // position: absolute;
        // top: 50%;
        // left: 50%;
        // transform: translate(-50%,-50%);
    }

    .title {
        font-size: 30px;
        line-height: 40px;
    }

    .sub-title {
        font-size: 16px;
        line-height: 24px;
    }

    .options {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .ant-form-item {
            margin: 0;
        }
    }

    // .login-form-forgot {
    //     float: right;
    //     font-size: 12px;
    //     position: absolute;
    //     top: 40%;
    //     right: 0;
    // }

    .login-form-signup {
        font-size: 12px;
    }

    .login-form-button {
        margin: 20px auto;
        display: block;
    }

    .ant-form-item-required::before {
        content: '';
    }
    .ant-card {
        font-size: 13px;
        margin-top: 5px;
        color:#fff;
        //background-color: #ffc107;
    }
    .ant-card-head-title {
        padding: 8px 0;
        color: #fff;
    }
    .ant-card-head {
        min-height: 40px;
        border-bottom: 1px solid #CCCCCC;
    }
    .ant-card-bordered {
        border: 1px solid #CCCCCC;
        //border-radius: 4px;
    }
    .ant-card-body {
        padding: 10px 24px;
        zoom: 1;
    }
    pre {
        white-space: pre-wrap;
        white-space: -moz-pre-wrap;
        white-space: -pre-wrap;
        white-space: -o-pre-wrap;
        word-wrap: break-word;
        font-family: "Poppins", sans-serif;
    }
    .language-select {
        position: absolute;
        z-index: 1;
        right: 10px;
        top: 20px;
        width: 150px;
        .ant-select-selection-selected-value {
            float: right;
            padding-right: 5px;
        }
        .ant-select-selection {
            border: 0 !important;
        }
        svg {
            transform: rotate(0) !important;
        }
    }
}

.login-layout {
    background: url('/images/background.jpg?id=2');
    width: 100%;
    height: 100vh;
    background-size: cover;
    overflow: auto;
    background-position: right top;

    header {
        display: none;
    }

    .login-form {
        background: #fff;
        padding: 30px 50px;
        left: 4.2%;
        top: 0;
        border: 0;
        box-shadow: 0;
        max-width: 509px;
        width: 100%;
        margin: 0 auto;
        position: absolute;
        transform: unset;

        & > p {
            display: none;
        }
    }

    .login-form-forgot {
        float: right;
        font-size: 12px;
        position: relative;
        display: inline-block;
        bottom: -7px;
        right: 0;
        z-index: 9999;
    }

    .ant-spin-blur {
        .login-form-forgot {
            top: 63%;
        }
    }

    .logo {
        width: 80%;
    }
}
.language-select-dropdown {
    transform: translateY(-25px) !important;
    & > div {
        z-index: 111;
    }
}
.twofactor{
    .input_factor{
        margin: 10px 0 ;
    }
    .anticon {
        display: none;
    }
    .ant-modal-confirm-content{
        margin-left: 0 !important;
    }
    .ant-modal-confirm-btns{
         button:first-child{
        background-color: #dcdcdc;
        color: #212121;

        }
    }
    .a_resend{
        color: #4e9de6;
        cursor: pointer;
    }
}