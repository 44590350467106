.add-license {
    background: url("/images/background.jpg?id=2");
    width: 100%;
    height: 100vh;
    background-size: cover;
    overflow: auto;
    background-position: right top;
    padding: 0 50px;
    padding-top: 10%;

    h1 {
        margin: 50px 0;
        text-align: left !important;
        color: white;
        font-size: 50px;
    }

    p {
        font-size: 20px;
        color: white;
        margin-bottom: 30px;
        max-width: 1000px;
    }

    label {
        color: white !important;
    }

    .license-key-form {
        display: flex;
        align-items: center;

        & > div {
            margin: 0;
        }

        button {
            margin-left: 10px;
        }

        .ant-form-item {
            width: 40%;

            .ant-form-item-label {
                width: 120px;
                text-align: left;
                padding-right: 10px;
            }

            .ant-form-item-control-wrapper {
                width: 100%;
            }
        }
    }

    .c.horizontal {
        .ant-form-item .ant-form-item-label {
            text-align: left;
            width: 200px;
            line-height: 30px;
            padding-right: 10px;
        }

        .form-item-container {
            margin-bottom: 0 !important;
        }

        .ant-form-item-control-wrapper {
            color: white;
        }

        .ant-form-item-control {
            width: 200px;
        }
    }

    hr {
        border-top: 0;
    }
}

.admin-create-license {
    .ant-form-item {
        .ant-form-item-label {
            padding-right: 10px;
            width: 200px;
            text-align: left;
        }
    }
}
